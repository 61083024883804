.sort_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 30%;
}
.job_list_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}
.jobs_list {
    width: 100%;
}
.mobile_filter_header {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.mobile_filter_header button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    /* border: 0.5px solid rgba(128, 128, 128, 0.171); */
}
.mobile_filter_header button img {
    height: 15px;
    width: 15px;
}
@media (max-width: 633px) {
    .sort_wrapper {
        width: auto;
    }
  }