.img_effect_all {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 128 0 / 38%);
    z-index: 2;
}