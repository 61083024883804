.available_job_swiper_slide {
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: space-evenly; */
    padding: 10px 5px 10px 5px;
}
.swiper-button-prev, .swiper-button-next {
    color: green !important;
}
.av_swiper_container {
    width: 90%;
}
.flexCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}
@media (max-width: 900px) {
    .av_swiper_container {
        width: 100%;
    }
  }