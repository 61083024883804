.no-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.profile-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.academy-profile-heading-h1 {
    font-size: 42px !important;
    font-weight: 600 !important;
    line-height: 52px !important;
}

.academy-profile-image-style {
    height: 290px !important;
    max-width: 290px !important;
}

.academy-profile-heading-h4 {
    font-size: 1.5rem !important;
}

.academy-profile-description {
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
}

.academy-profile-logo {
    padding: 30px 0;
}

.academy-profile-header-cover {
    max-width: 90%;
}

.academy-profile-banners {
    max-height: 560px;
    overflow: hidden;
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.academy-profile-banners-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    transform: translate3d(0, 0, 0);
}

.banners-track {
    opacity: 1;
    width: 100%;
    transform: translate3d(0, 0, 0);
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.academy-profile-jobs {
    padding: 64px 0 72px !important;
    position: relative;
}

.academy-profile-heading-h2 {
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    padding: 0 0 40px !important;
    margin-bottom: 0 !important;
}

.academy-profile-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.academy-profile-job-listing {
    border-bottom: 1px solid #dedede;
    -webkit-transition: all .25s ease-in 0s;
    padding-bottom: 6px;
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.academy-profile-job-post {
    line-height: 16px;
    position: relative;
    padding-right: 15px !important;
    padding-left: 15px !important;
}

@media (min-width: 767px) {
    .academy-profile-job-post {
        min-height: 100%;
    }
}

.academy-profile-job-post-thumb {
    position: relative;
    padding: 0;
    float: left;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 15px;
    display: block;
}

@media (min-width: 768px) {
    .academy-profile-job-post-thumb {
        max-height: 120px;
        overflow: hidden;
    }
}

.academy-profile-job-post-details {
    float: left;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    position: relative;
    width: calc(100% - 94px);
}

.academy-profile-job-features {
    color: #28c6a8;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 10px;
}

.academy-profile-job-title {
    font-weight: 600;
    font-size: 18px !important;
    margin: 0;
    line-height: 24px;
    padding: 0;
    display: block;
    color: #000;
}

.academy-profile-job-title a {
    color: #212121;
    display: inline-flex;
    margin-right: 15px;
    border-bottom: 1px solid transparent;
}

.academy-profile-job-marker {
    display: inline-flex;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    padding: 2px 10px;
}

.academy-profile-job-start-date {
    color: rgba(0, 0, 0, .54);
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
}

.academy-profile-job-location {
    padding: 5px 5px 0 0;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, .56);
    line-height: 20px;
}

.academy-profile-job-location i {
    color: #707070;
    padding-right: 3px;
}

.academy-profile-job-links {
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
}

.academy-profile-job-contents {
    display: inline;
}

.academy-profile-job-details {
    background-color: #68c441;
    padding: 9px 15px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    display: inline-block;
    color: #fff;
    min-width: 123px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all .25s ease-in 0s;
    -moz-transition: all .25s ease-in 0s;
    -o-transition: all .25s ease-in 0s;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
    text-transform: uppercase;
    border-radius: 4px;
    border-width: 0;
}

@media ((max-width: 480px)) {
    .academy-profile-job-details {
        margin-top: 0;
        white-space: unset;
        height: auto;
    }
}

@media ((max-width: 480px)) {
    .academy-profile-job-details {
        margin: 10px 0;
        display: inline-block;
        min-width: 78px;
        width: 78px;
        font-size: 12px;
        padding: 8px 0;
    }
}

@media (min-width: 768px) {
    .academy-profile-job-details {
        margin: 20px 0 0;
    }
}

.academy-profile-job-view-details a {
    color: #28c6a8 !important;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    font-weight: 600;
    margin-top: 15px;
    -webkit-transition: all .25s ease-in 0s;
}

@media (max-width: 480px) {
    .academy-profile {
        padding: 0 15px;
    }
}

.profile-back {
    position: relative;
  }
  
  .profile-back img {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    border-radius: 1.25rem;
  }
  
  @media only screen and (max-width: 35.9375rem) {
    .profile-back img {
      height: 20rem;
    }
  }
  
  .profile-back .social-btn {
    position: absolute;
    bottom: 3rem;
    right: 2rem;
    z-index: 1;
  }
  
  .profile-back .social-btn .social {
    background: rgba(239, 239, 239, 0.22);
    border: 0;
    margin-right: 1rem;
  }
  
  .profile-back .social-btn .btn {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .profile-back .social-btn .btn:hover {
    color: #fff;
  }
  
  @media only screen and (max-width: 87.5rem) {
    .profile-back .social-btn .btn {
      font-size: 0.7rem;
    }
  }
  
  @media only screen and (max-width: 61.9375rem) {
    .profile-back .social-btn {
      bottom: unset;
      top: 3rem;
    }
  }
  
  @media only screen and (max-width: 35.9375rem) {
    .profile-back .social-btn {
      right: 0;
      padding: 0 15px;
    }
  
    .profile-back .social-btn .social {
      margin-right: 0.2rem;
      margin-bottom: 0.5rem;
    }
  }
  
  .profile-back:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    /* border-radius: 1.25rem; */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+39,000000+86&0.7+18,0.5+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.7) 18%, rgba(0, 0, 0, 0.65) 39%, rgba(0, 0, 0, 0.53) 86%, rgba(0, 0, 0, 0.5) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7) 18%, rgba(0, 0, 0, 0.65) 39%, rgba(0, 0, 0, 0.53) 86%, rgba(0, 0, 0, 0.5) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 18%, rgba(0, 0, 0, 0.65) 39%, rgba(0, 0, 0, 0.53) 86%, rgba(0, 0, 0, 0.5) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3000000', endColorstr='#80000000', GradientType=0);
    /* IE6-9 */
  }


  .profile-back .social-btn {
    position: absolute;
    bottom: 3rem;
    right: 2rem;
    z-index: 1;
  }
  
  .profile-back .social-btn .social {
    background: rgba(239, 239, 239, 0.22);
    border: 0;
    margin-right: 1rem;
  }
  
  .profile-back .social-btn .btn {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .profile-back .social-btn .btn:hover {
    color: #fff;
  }
  
  @media only screen and (max-width: 87.5rem) {
    .profile-back .social-btn .btn {
      font-size: 0.7rem;
    }
  }
  
  @media only screen and (max-width: 61.9375rem) {
    .profile-back .social-btn {
      bottom: unset;
      top: 3rem;
    }
  }
  
  @media only screen and (max-width: 35.9375rem) {
    .profile-back .social-btn {
      right: 0;
      padding: 0 15px;
    }
  
    .profile-back .social-btn .social {
      margin-right: 0.2rem;
      margin-bottom: 0.5rem;
    }
  }

  .profile-pic {
    margin-top: -9rem;
    position: relative;
    padding-left: 6rem;
  }
  
  .profile-pic img {
    height: 13.688rem;
    width: 13.688rem;
    border-radius: 1.25rem;
    border: 0.5rem solid #fff;
    margin-right: 2rem;
  }
  
  .profile-pic .profile-info2 {
    color: #fff;
  }
  
  .profile-pic .profile-info2 h2,
  .profile-pic .profile-info2 .h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #fff;
  }
  
  .profile-pic .profile-info2 h4,
  .profile-pic .profile-info2 .h4 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #B1C1C8;
  }
  
  @media only screen and (max-width: 87.5rem) {
    .profile-pic {
      margin-top: -6rem;
      padding-left: 1rem;
    }
  
    .profile-pic img {
      height: 7.688rem;
      width: 7.688rem;
    }
  
    .profile-pic h2,
    .profile-pic .h2 {
      font-size: 1.5rem !important;
    }
  
    .profile-pic h4,
    .profile-pic .h4 {
      font-size: 1rem !important;
    }
  }
  
  @media only screen and (max-width: 61.9375rem) {
    .profile-pic {
      margin-top: -8rem;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    .profile-pic img {
      height: 6.688rem;
      width: 6.688rem;
      margin-right: 1rem;
      border-width: 3px;
    }
  
    .profile-pic h4,
    .profile-pic .h4 {
      font-size: 0.7rem !important;
    }
  }

  .d-flex {
    display: flex !important;
  }

  .profile-pic .profile-info2 {
    color: #fff;
  }
  
  .profile-pic .profile-info2 h2,
  .profile-pic .profile-info2 .h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #fff;
  }
  
  .profile-pic .profile-info2 h4,
  .profile-pic .profile-info2 .h4 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #B1C1C8;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  
  .d-block {
    display: block !important;
  }