.job_post {
    position: relative;
    min-height: 500px;
    max-width: 250px;
}
.job_post_img {
    height: 300px;
    width: 250px;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.658);
}
.academy_profile {
    height: 70px;
    width: 70px !important;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.692);
    position: absolute;
    bottom: 46%;
    left: 90px;
    z-index: 12;
    background-color: #fff;
}
.date {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
    font-weight: 500;
    color: rgb(109, 109, 109);
}
.job_post_title {
    margin-top: 14px;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    color: #eb9d3b;
}
.job_post_location {
    font-weight: 700;
    color: #eb9d3b;
    font-size: 13px;
}
.job_post_data_wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    position: absolute;
    left: 5%;
    top: 230px;
    gap: 5px;
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.692);
    z-index: 10;
    min-height: 245px;
}
.job_post_numbers {
    width: 100%;
    display: grid;
    grid-template-columns: 47% 6% 47%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.hr {
    height: 30px;
    width: 2px;
    background-color: #eb9d3b;
}
.job_post_number_element {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}
.job_post_number_element label {
    font-weight: 500;
    font-size: 14px;
    color: rgb(112, 111, 111);;
}
.apply_now {
    width: 80%;
    background: linear-gradient(to right, #f08605, #ecb26a 80%);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: grid;
    grid-template-columns: calc(100% - 20px) 15px;
    align-items: center;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    min-height: 40px;
}
.apply_now img {
    height: 15px;
    width: 15px;
    cursor: pointer;
}
.apply_now label {
    cursor: pointer;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -3px !important;
}
.swiper-pagination-bullet {
    background: #a2c563 !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    background: #2f541f !important;
}
.MFS_list {
    padding: 20px 5px 20px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media (max-width: 575px) {
    .job_post {
        max-width: unset;
    }
    .academy_profile {
        left: calc(50% - 35px);
    }
  }