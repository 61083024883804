.upper_footer_icon_area {
    background-color: #0B365C;
    padding-top: 40px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.new_footer_logo {
    height: 70px;
    width: 70px;
}
.footer_icons_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
}
.new_footer_widget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    gap: 10px;
}
.new_footer_widget ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.new_footer_widget ul li a {
    color: rgba(255, 255, 255, 0.827) !important;
    font-size: 13px;
    text-transform: none !important;
    text-align: left;
}
.new_footer_widget ul li a::before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.827) !important;

}
.upper_footer_icon_area label {
    color: #fff;
    font-size: 18px;
    text-align: left;
}
@media (max-width: 750px) {
    .new_footer_widget {
        width: 30%;
    }
    .upper_footer_icon_area {
        justify-content: flex-start;
        gap: 20px;
        padding: 40px 10px 10px 10px;
    }
  }
  @media (max-width: 550px) {
    .new_footer_widget {
        width: 40%;
    }
  }