.pages_with_header {
    /* margin-top: -80px; */
    z-index: 99;
}
.all_jibs_wrapper {
    display: grid;
    align-items: flex-start;
    justify-content: center;
    grid-template-columns: 70% 30%;
    gap: 30px;
    padding: 70px 40px 60px 40px;
}
.jobs_list_filter button {
    align-self: flex-end;
}
@media (max-width: 1050px) {
    .all_jibs_wrapper {
        padding: 70px 40px 40px 40px;
        /* grid-template-columns: calc(65% - 20px) calc(30% - 20px); */
    }
  }
  @media (max-width: 919px) {
    .all_jibs_wrapper {
        padding: 70px 10px 10px 10px;
        grid-template-columns: calc(65% - 5px) calc(30% - 5px);
    }
  }
  @media (max-width: 670px) {
    .all_jibs_wrapper {
        padding: 20px 10px 40px 10px;
        grid-template-columns: 100%;
    }
  }