.jobDetailsBtn1, .jobDetailsBtn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    min-width: 138px;
}
.jobDetailsBtn2 {
    background-color: #a2c563;
    color: #fff;
}
.jobDetailsBtnsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}
.jobSetails label .span {
    font-weight: bolder;
    font-size: 14px;
    color: #000;
    /* margin-bottom: 3px; */
}
.jobSetails label {
    font-size: 13px;
    color: rgb(85, 84, 84);
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: flex-end;
}
.mapEffect {
    max-width: 400px;
    max-height: 400px;
    background-color: rgba(128, 128, 128, 0.212);
}
.JobRightSection {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.JobRightSection .job_filter_box {
    width: 100%;
}
@media (max-width: 900px) {
    .all_jibs_wrapper_900 {
        padding: 20px 10px 40px 10px;
        grid-template-columns: 100%;
    }
    .JobRightSection {
        width: 100%;
    }
    .JobDetailsSection {
        padding: 0px;
    }
  }