.made_for_schools {
    display: grid;
    grid-template-columns: 30% 40%;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 80px 10px 60px 10px;
    min-height: 500px;
}
.MFS_img {
    height: 450px;
}
.MDF_imgs_wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 450px;
}
/* .white_effect {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    z-index: 2;
} */
/* .U_sign {
}
.Z_sign {
} */
.MFS_section_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.MFS_section_content h2 {
    font-weight: 800;
    font-size: 35px;
    text-align: left;
}
.MFS_section_content p {
    color: #4b4a4a;
    text-align: left;
    font-size: 19px;
}
.MFS_section_content p, .MFS_section_content h2 {
    margin: 0px;
}
.listItemIcon {
    height: 15px;
    width: 15px;
}
.MFS_list_item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 54px;
    
}
.MFS_list_item label {
    color: #4b4a4a;
    font-weight: 500;
    font-size: 16px;
}
.reg_now {
    width: 48%;
    margin-left: 70px;
    background: linear-gradient(to right, #f08605, #ecb26a 80%);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    min-height: 34px;
    box-shadow: 1px 0px 8px #f0860557;
    cursor: pointer;
}

@media (max-width: 1500px) {
    .MFS_img {
        height: 350px;
    }
    
  }
  @media (max-width: 1330px) {
    .made_for_schools {
        grid-template-columns: 35% 45%;
    }
  }
  @media (max-width: 1150px) {
    .made_for_schools {
        grid-template-columns: 40% 50%;
    }
    .MFS_img {
        height: 250px;
    }
    .MFS_section_content p {
        font-size: 15px;
    }
  }
  @media (max-width: 1150px) {
    .made_for_schools {
        min-height: 400px;
        padding: 10px
    }
  }
  @media (max-width: 800px) {
    .MFS_section_content p {
        font-size: 13px;
    }
    .MFS_section_content h2 {
        font-size: 20px;
    }
    .MFS_list_item {
        margin-left: 0px;
    }
    .reg_now {
        width: 70%;
        margin: 0px;
    }
    .MFS_list_item label {
        font-size: 12px;
    }
    .MFS_img {
        height: 200px;
    }
  }
  @media (max-width: 617px) {
    .MFS_section_content p {
        font-size: 15px;
    }
    .MFS_section_content h2 {
        font-size: 20px;
    }
    .MFS_list_item {
        margin-left: 0px;
    }
    .reg_now {
        width: 70%;
        margin: 0px;
    }
    .MFS_list_item label {
        font-size: 12px;
    }
    .MFS_img {
        height: 170px;
    }
  }
  @media (max-width: 532px) {
    .MFS_img {
        height: 160px;
    }
  }
  @media (max-width: 500px) {
    .made_for_schools {
        display: flex;
        flex-direction: column-reverse;
        grid-template-columns: unset;
        align-items: center;
        justify-content: center;
    }
    .MFS_section_content {
        align-items: center;

    }
    .MFS_section_content p {
        text-align: center;
    }
    .MFS_section_content h2 {
        text-align: center !important;
    }
    .MDF_imgs_wrapper {
        height: auto;
    }
    .MFS_img {
        height: 230px;
    }
  }
  @media (max-width: 386px) {
    .MFS_img {
        height: 150px;
    }
  }