.CB_section {
    height: 400px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: calc( 50% - 15px) calc( 50% - 15px);
    gap: 30px;
    background-color: #d7e0c8;
    margin-top: 120px;
    /* overflow: hidden; */
}
.notify_section_img {
    height: 423px;
}
.notify_section_img_wraper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: -23px;
}
.notify_section_content_wraper {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
}
.notify_section_content_wraper label {
    color: #668b37;
    text-align: left;
    font-size: 22px;
    font-weight: 800;
}
.notify_section_content_wraper h1 {
    font-size: 35px;
    font-weight: 800;
    margin: 0px;
}
.notify_section_content_wraper p {
    cursor: pointer;
}
@media (max-width: 1200px) {
    .CB_section {
        gap: 0px;
        grid-template-columns: 50% 50% ;

    }
  }
@media (max-width: 1000px) {
    .notify_section_img {
        height: 380px;
        margin-top: 43px;
    }
    .notify_section_content_wraper {
        margin-top: 130px;
    }
  }
  @media (max-width: 900px) {
    .CB_section {
        height: 330px;
    }
    .notify_section_img {
        height: 320px;
        margin-top: 33px;
    }
    .notify_section_img_wraper {
        justify-content: center;
    }
    .notify_section_content_wraper {
        margin-top: 20px;
    }
    .notify_section_content_wraper h1 {
        font-size: 25px;
    }
    .notify_section_content_wraper label {
        font-size: 18px;
    }
    .notify_section_content_wraper p {
        margin: 0px;
    }
  }
  @media (max-width: 765px) {
    .notify_section_img {
        height: 280px;
        margin-top: 73px;
    }
    .notify_section_content_wraper {
        margin-top: 60px;
    }
    .notify_section_content_wraper h1 {
        font-size: 22px;
    }
    .notify_section_content_wraper label {
        font-size: 15px;
    }
  }
  @media (max-width: 634px) {
    .CB_section {
        height: 200px;
    }
    .notify_section_img {
        height: 210px;
        margin-top: 12px;
    }
    .notify_section_content_wraper {
        margin-top: 10px;
    }
    .notify_section_content_wraper h1 {
        font-size: 20px;
    }
    .notify_section_content_wraper p {
        font-size: 12px;
    }
  }
  @media (max-width: 500px) {
    .CB_section {
        grid-template-columns: 60% 40%;
    }
  }
  @media (max-width: 420px) {
    .CB_section {
        grid-template-columns: 50% 50%;
    }
    .notify_section_img {
        height: 150px;
        margin-top: 73px;
    }
  }
  @media (max-width: 350px) {
    .CB_section {
        grid-template-columns: 60% 40%;
    }
    .notify_section_content_wraper h1 {
        font-size: 15px;
    }
    .notify_section_content_wraper p {
        font-size: 12px;
    }
  }