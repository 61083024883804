.job_details_content_section ul {
    color: rgb(104, 103, 103);
}
.job_details_content_section {
    padding: 10px;
}
.JobDetailsSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
@media (max-width: 500px) {
    .job_details_content_section ul {
        font-size: 13px;
        padding: 5px;
    }
    .job_details_content_section p {
        font-size: 13px;
    }
  }