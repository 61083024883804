.job_type {
    height: 300px;
    width: 100%;
    position: relative;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.job_type_img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 15px;
}
.img_effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(162 197 98 / 33%);
    text-shadow: 1px 0px 8px gray;
    border-radius: 15px;
    z-index: 2;
}
.job_type_title {
    z-index: 3;
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    text-shadow: 1px 0px 8px gray;
    cursor: pointer;


}

.location-img img {
    min-height: 425px;
    object-fit: cover;
}