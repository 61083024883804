.blog-btn {
    display: flex;
    align-items: center;
    gap: 5px;
}
.blog_content {
    text-align: left;
}
.blog-card h3 {
    text-align: left;
}
.blog-text ul {
    display: flex;
}
.blog-text ul li{
    display: flex !important;
    align-items: center;
    gap: 5px;
}
.blog_img {
    max-height: 400px;
    object-fit: cover;
    width: 100%;
}